.file-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.file-container {
    display: flex;
    width: 20em;
    border: lightgray dashed 1px;
    border-radius: 20px;
    padding: 1.5em;
    margin-bottom: 1em;
}

.file-container * {
    align-self: center;
}

.file-container div {
    flex-grow: 20;
}

.file-container svg:first-child {
    padding-right: 1em;
}

.file-upload-container {
    margin-top: 0.5em;
    font-size: xxx-large;
}

.file-upload-container svg {
    padding: 20px;
    border-width: 1px;
}

.file-upload-container svg:hover {
    border: lightgray dashed 1px;
    padding: 19px;
}
