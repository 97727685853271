
.Invoice {
}

.Invoice h1 {
    /*color: white;*/
}

.invoice-input {
    width: 5em;
}

.week {
    font-size: x-large;
}

.week span {
    /*background-color: white;*/
}

.week input {
    width: 50px;
    font-size: large;
}

.week button {
    font-size: x-large;
}

.invoice-week-table {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.invoice-week-table-hours-cell {
    display: flex;
    align-items: center;
}
